import type { ConferenceFooterMenu,ConferenceSocialLinks } from '@/types/footer/demos/conferenceFooter';
import { BrandFacebookIcon, BrandLinkedinIcon, BrandTwitterIcon } from 'vue-tabler-icons';
const ConferenceFooterExloreLinks: ConferenceFooterMenu[] = [
    {
        title: 'About',
        url: '/about',
        tag:false,
    },
    {
        title: 'Pricing',
        url: '#',
        tag:false,
    },
    {
        title: 'Blog',
        url: '/blog',
        tag:false,
    },
    {
        title: 'Careers',
        url: '#',
        tag:true,
    },
    {
        title: 'Contact',
        url: '/contact',
        tag:false,
    }

]

const ConferenceFooterFeatureLinks: ConferenceFooterMenu[] = [
    {
        title: 'Home',
        url: '/',
        tag:false,
    },
    {
        title: 'About Us',
        url: '/about-us',
        tag:false,
    }
]

const ConferenceFooterResourceLinks: ConferenceFooterMenu[] = [
    {
        title: 'Founders',
        url: '/founders',
        tag:false,
    },
    {
        title: 'Investors',
        url: '/investors',
        tag:false,
    },
    {
        title: 'Workers',
        url: '/workers',
        tag:false,
    }
]

const ConferenceFooterPlateformLinks: ConferenceFooterMenu[] = [
    {
        title: 'Blog',
        url: '/blog',
        tag:false,
    }    

]

const ConferenceSocialLinksIcons: ConferenceSocialLinks[] = [
    {
        icon: BrandTwitterIcon,
        url:'https://twitter.com/TreeletNet',
    },
    {
        icon: BrandLinkedinIcon,
        url:'https://www.linkedin.com/company/treelet',
    }
]

export { ConferenceFooterExloreLinks,ConferenceFooterFeatureLinks,ConferenceFooterResourceLinks,ConferenceFooterPlateformLinks,ConferenceSocialLinksIcons }