<script setup lang="ts">
import WhiteLogoIcon from "@/layouts/logo/WhiteLogoIcon.vue";
import { ConferenceSocialLinksIcons } from "@/_mockApis/footer/ConferenceFooter";
import SnackbarComponent from "@/common/SnackbarComponent.vue";
import {
  ConferenceFooterFeatureLinks,
  ConferenceFooterResourceLinks,
  ConferenceFooterPlateformLinks,
} from "@/_mockApis/footer/ConferenceFooter";

let snackbar = ref({
  show: false,
  message: null,
  color: null,
  timeout: 0,
});

import { guestProfile } from "@/store/guestProfile";
const useGuestProfileStore = guestProfile();
let storeOne = useGuestProfileStore.one;

const emailRules = ref([
  (v: string) => !!v || "E-mail is required",
  (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
]);
// const runtimeConfig = useRuntimeConfig();

const Regform = ref();

// const appId = computed(() => {
//   return runtimeConfig.public.NUXT_MY_APP_ID;
// });

const appId = ref("tlet");

async function submitForm() {
  try {
    const { valid } = await Regform.value.validate();
    if (valid) {
      let user = await useGuestProfileStore.save({ appId: appId.value });
      Regform.value.reset(); // Reset the form after successful submission
      snackbar.value = {
        message: "You have Subscribed successfully",
        color: "success",
        show: true,
        timeout: 2000,
      };
    }
  } catch (error: any) {
    snackbar.value = {
      message: "You have Unsubscribe.",
      color: "error",
      show: true,
      timeout: 2000,
    };
    console.log(error);
  }
}
</script>
<template>
  <div
    class="it-footer conference-footer bg-themecolordark pt-md-16 pt-8 pb-md-10 pb-5 position-relative"
  >
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    <div class="it-footer-bg"></div>
    <div class="py-md-4 py-sm-3">
      <v-container class="pb-3">
        <div class="border-bottom-dark pb-6 position-relative z-index1">
          <v-row class="">
            <v-col cols="3" sm="6">
              <pre>{t}</pre>
              <!-- <WhiteLogoIcon /> -->
            </v-col>
            <v-col cols="9" sm="6">
              <div class="">
                <ul class="d-flex align-center justify-end">
                  <li v-for="social in ConferenceSocialLinksIcons" class="ml-4">
                    <NuxtLink
                      :to="social.url"
                      class="text-decoration-none text-surface"
                    >
                      <component :is="social.icon" stroke-width="2" size="22" />
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="pt-md-16 pt-8 mt-md-3">
          <v-col cols="6" lg="2" md="2" sm="4" class="d-none d-sm-block">
            <div class="font-weight-medium mb-5">Main</div>
            <ul>
              <li
                class="mb-2"
                v-for="link in ConferenceFooterFeatureLinks"
                :key="link.title"
              >
                <NuxtLink
                  :to="link.url"
                  class="text-muted text-white text-decoration-none text-capitalize font-weight-regular position-relative"
                >
                  {{ link.title }}
                  <v-chip v-if="link.tag" class="bg-success">hiring</v-chip>
                </NuxtLink>
              </li>
            </ul>
          </v-col>
          <v-col cols="6" lg="2" md="2" sm="4" class="d-none d-sm-block">
            <div class="font-weight-medium mb-5">Proposition</div>
            <ul>
              <li
                class="mb-2"
                v-for="link in ConferenceFooterResourceLinks"
                :key="link.title"
              >
                <NuxtLink
                  :to="link.url"
                  class="text-muted text-white text-decoration-none text-capitalize font-weight-regular position-relative"
                >
                  {{ link.title }}
                  <v-chip v-if="link.tag" class="bg-success">hiring</v-chip>
                </NuxtLink>
              </li>
            </ul>
          </v-col>
          <v-col cols="6" lg="3" md="3" sm="4" class="d-none d-sm-block">
            <div class="font-weight-medium mb-5">Latest</div>
            <ul>
              <li
                class="mb-2"
                v-for="link in ConferenceFooterPlateformLinks"
                :key="link.title"
              >
                <NuxtLink
                  :to="link.url"
                  class="text-muted text-white text-decoration-none text-capitalize font-weight-regular position-relative"
                >
                  {{ link.title }}
                  <v-chip v-if="link.tag" class="bg-success">hiring</v-chip>
                </NuxtLink>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <div class="newsletter">
              <div class="mb-6">Sign up for updates</div>
              <v-form class="banner-form" ref="Regform">
                <div class="d-flex align-center flex-fill">
                  <v-text-field
                    v-model="storeOne.email"
                    :rules="emailRules"
                    variant="outlined"
                    density="comfortable"
                    color="white"
                    class="position-relative rounded-md"
                    label="Enter Your Email address"
                    single-line
                    hide-details
                  >
                    <v-btn
                      color="white"
                      :ripple="false"
                      size="large"
                      class="text-white no-effect position-absolute right-0 mr-n3 px-0 mt-n1"
                      variant="text"
                      density="compact"
                      @click="submitForm"
                    >
                      <SendIcon class="text-white" size="22" />
                    </v-btn>
                  </v-text-field>
                </div>
              </v-form>
              <p class="text-muted text-white mt-6">
                © 2024. All rights reserved by Treelet Inc.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>